export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    fixed: "left",
    width: 50,
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: "客户编号",
    dataIndex: "number",
    key: "number",
    sorter: true,
  },
  {
    title: "客户名称",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "联系人",
    dataIndex: "contacts",
    key: "contacts",
  },
  {
    title: "电话",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "地址",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "状态",
    dataIndex: "is_active",
    key: "is_active",
    width: 80,
    scopedSlots: { customRender: "is_active" },
  },
  {
    title: "备注",
    dataIndex: "remark",
    key: "remark",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    width: 340,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
